<template>
  <svg width="166" height="160" viewBox="0 0 166 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M165.163 121.459H156.938L157.702 142.14H164.399L165.163 121.459ZM161.05 152.01C163.209 152.01 165.133 150.188 165.163 147.897C165.133 145.665 163.209 143.843 161.05 143.843C158.774 143.843 156.908 145.665 156.938 147.897C156.908 150.188 158.774 152.01 161.05 152.01Z" fill="#02C076"/>
    <path d="M16.2154 130.86H24.0293C23.9999 124.955 19.4467 121.048 12.1616 121.048C5.00877 121.048 -0.0437769 124.896 0.000285952 130.625C-0.0144017 135.325 3.24625 137.939 8.57787 139.085L11.5741 139.731C14.9229 140.465 16.0686 141.303 16.0979 142.668C16.0686 144.152 14.7173 145.195 12.1029 145.195C8.97443 145.195 7.12379 143.726 6.9916 140.965H0.0982035C0.127579 148.514 4.3772 151.892 12.2204 151.892C19.8433 151.892 24.4111 148.602 24.4405 142.668C24.4111 138.086 21.6792 134.869 15.0404 133.445L12.5729 132.916C9.7235 132.314 8.44568 131.477 8.51911 130.037C8.5338 128.715 9.63538 127.746 12.1616 127.746C14.8054 127.746 16.0539 128.906 16.2154 130.86Z" fill="white"/>
    <path d="M30.4531 151.54H39.207L42.3795 139.437H42.6145L45.787 151.54H54.5409L60.1809 128.98H52.0733L49.6058 142.61H49.4296L46.4333 128.98H38.5607L35.6819 142.727H35.5057L32.9207 128.98H24.8131L30.4531 151.54Z" fill="white"/>
    <path d="M66.8667 151.892C69.8042 151.892 72.0221 150.894 73.388 148.367H73.5643V151.54H81.1431V136.088C81.1431 131.917 77.1921 128.686 70.7443 128.686C64.0026 128.686 60.6832 132.211 60.4042 136.323H67.8655C68.0564 134.928 69.0846 134.267 70.6268 134.267C72.0368 134.267 73.0355 134.913 73.0355 136.088V136.147C73.0355 137.454 71.5961 137.866 67.8067 138.145C63.1507 138.483 59.5229 140.363 59.5229 145.312C59.5229 149.807 62.5486 151.892 66.8667 151.892ZM69.5105 146.781C68.1299 146.781 67.1605 146.105 67.1605 144.842C67.1605 143.682 67.983 142.786 69.863 142.492C71.1555 142.287 72.2424 142.022 73.0943 141.67V143.55C73.0943 145.606 71.3464 146.781 69.5105 146.781Z" fill="white"/>
    <path d="M82.9489 160H91.0565V147.839H91.174C92.114 150.13 94.2878 151.834 97.4603 151.834C102.278 151.834 106.332 148.074 106.332 140.26C106.332 132.093 101.984 128.686 97.5191 128.686C94.1703 128.686 92.0553 130.625 91.174 132.916H90.9978V128.98H82.9489V160ZM90.8803 140.26C90.8803 136.911 92.2315 134.913 94.4641 134.913C96.6966 134.913 97.9891 136.911 97.9891 140.26C97.9891 143.608 96.6966 145.606 94.4641 145.606C92.2315 145.606 90.8803 143.55 90.8803 140.26Z" fill="white"/>
    <path d="M122.289 141.67C122.304 143.902 121.011 145.254 118.999 145.254C116.957 145.254 115.782 143.902 115.768 141.67V128.98H107.66V143.373C107.675 148.309 110.789 151.834 115.533 151.834C118.896 151.834 121.481 150.13 122.465 147.251H122.7V151.54H130.397V128.98H122.289V141.67Z" fill="white"/>
    <path d="M132.687 151.54H152.486V145.371H143.145V145.254L152.134 133.915V128.98H133.04V135.148H142.557V135.266L132.687 147.075V151.54Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M102.163 0.739904C105.493 -1.27851 109.759 1.06514 109.842 4.95847L110.344 28.5563L134.793 32.7709C138.63 33.4324 140.313 38.0002 137.821 40.9929L121.552 60.5325L133.907 70.4592C136.943 72.8983 136.2 77.709 132.569 79.1184L110.244 87.7865L112.98 101.584C113.738 105.404 110.077 108.611 106.39 107.357L90.464 101.942C72.3005 110.763 50.5391 106.189 37.4176 91.2233C56.8525 96.8342 72.4296 90.9691 79.8235 84.9068C81.6239 83.4306 81.8867 80.7744 80.4105 78.974C78.9343 77.1736 76.2781 76.9107 74.4777 78.3869C68.3402 83.4192 51.5769 90.0341 29.8771 79.2449C21.2635 59.1081 28.5577 35.1847 47.8279 23.5055C56.3072 18.3664 65.8152 16.3799 74.9862 17.2109L102.163 0.739904ZM66.0289 51.9099C62.0467 54.3234 56.862 53.0518 54.4485 49.0696C52.0351 45.0875 53.3067 39.9028 57.2889 37.4893C61.271 35.0758 66.4557 36.3475 68.8692 40.3296C71.2827 44.3118 70.011 49.4964 66.0289 51.9099Z" fill="#02C076"/>
  </svg>

</template>

<script>
export default {
  name: "LogoImg"
}
</script>

<style scoped>

</style>