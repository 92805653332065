<template>
  <svg width="360" height="562" viewBox="0 0 360 562" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.28" filter="url(#filter0_f_1803_203)">
      <path d="M259.71 141.266C249.283 113.732 107.094 99.2147 50.21 199.828C45.0467 208.961 42.316 219.251 40.7248 229.621L34.7952 268.266C32.9426 280.34 32.9444 292.627 34.8007 304.7L37.6483 323.222C41.2187 346.445 51.2852 368.328 67.1182 385.688C83.6204 403.782 101.019 421.052 110.82 421.687C129.497 422.898 193.751 438.854 193.751 438.854C193.751 438.854 254.679 451.668 324.795 414.676C354.292 392.916 408.94 325.68 407 306.359C403.209 268.595 360.247 300.791 335.252 313.137C310.071 329.113 202.343 377.18 175.169 327.762C115.849 219.885 324.795 313.137 259.71 141.266Z" fill="#6F46FF" fill-opacity="0.2"/>
      <path d="M259.71 141.266C249.283 113.732 107.094 99.2147 50.21 199.828C45.0467 208.961 42.316 219.251 40.7248 229.621L34.7952 268.266C32.9426 280.34 32.9444 292.627 34.8007 304.7L37.6483 323.222C41.2187 346.445 51.2852 368.328 67.1182 385.688C83.6204 403.782 101.019 421.052 110.82 421.687C129.497 422.898 193.751 438.854 193.751 438.854C193.751 438.854 254.679 451.668 324.795 414.676C354.292 392.916 408.94 325.68 407 306.359C403.209 268.595 360.247 300.791 335.252 313.137C310.071 329.113 202.343 377.18 175.169 327.762C115.849 219.885 324.795 313.137 259.71 141.266Z" fill="url(#paint0_linear_1803_203)"/>
      <path d="M259.71 141.266C249.283 113.732 107.094 99.2147 50.21 199.828C45.0467 208.961 42.316 219.251 40.7248 229.621L34.7952 268.266C32.9426 280.34 32.9444 292.627 34.8007 304.7L37.6483 323.222C41.2187 346.445 51.2852 368.328 67.1182 385.688C83.6204 403.782 101.019 421.052 110.82 421.687C129.497 422.898 193.751 438.854 193.751 438.854C193.751 438.854 254.679 451.668 324.795 414.676C354.292 392.916 408.94 325.68 407 306.359C403.209 268.595 360.247 300.791 335.252 313.137C310.071 329.113 202.343 377.18 175.169 327.762C115.849 219.885 324.795 313.137 259.71 141.266Z" fill="url(#paint1_linear_1803_203)"/>
      <path d="M259.71 141.266C249.283 113.732 107.094 99.2147 50.21 199.828C45.0467 208.961 42.316 219.251 40.7248 229.621L34.7952 268.266C32.9426 280.34 32.9444 292.627 34.8007 304.7L37.6483 323.222C41.2187 346.445 51.2852 368.328 67.1182 385.688C83.6204 403.782 101.019 421.052 110.82 421.687C129.497 422.898 193.751 438.854 193.751 438.854C193.751 438.854 254.679 451.668 324.795 414.676C354.292 392.916 408.94 325.68 407 306.359C403.209 268.595 360.247 300.791 335.252 313.137C310.071 329.113 202.343 377.18 175.169 327.762C115.849 219.885 324.795 313.137 259.71 141.266Z" stroke="black"/>
    </g>
    <defs>
      <filter id="filter0_f_1803_203" x="-87.0929" y="0.5" width="614.643" height="561" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="60" result="effect1_foregroundBlur_1803_203"/>
      </filter>
      <linearGradient id="paint0_linear_1803_203" x1="227.358" y1="120.672" x2="227.358" y2="477.539" gradientUnits="userSpaceOnUse">
        <stop stop-color="#724AFF"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1803_203" x1="383.547" y1="381.794" x2="148.054" y2="254.618" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1B1A1C"/>
        <stop offset="1" stop-color="#1B1A1C" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "BackgroundImgMob"
}
</script>

<style scoped>

</style>