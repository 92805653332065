<template>
  <svg width="990" height="881" viewBox="0 0 990 881" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.28" filter="url(#filter0_f_1803_190)">
      <path d="M574.884 161.485C553.586 105.243 257.098 76.1891 148.972 292.386C144.279 301.77 141.412 312.031 139.821 322.401L122.795 433.36C120.943 445.434 120.944 457.721 122.801 469.794L136.744 560.487C140.314 583.71 150.582 605.398 166.166 622.982C204.905 666.695 253.008 720.082 277.455 721.667C314.764 724.084 443.12 755.959 443.12 755.959C443.12 755.959 564.834 781.557 704.901 707.66C763.826 664.191 872.992 529.879 869.117 491.281C861.544 415.843 775.72 480.159 725.79 504.821C675.488 536.737 460.284 632.757 406 534.038C287.5 318.538 704.901 504.821 574.884 161.485Z" fill="#6F46FF" fill-opacity="0.2"/>
      <path d="M574.884 161.485C553.586 105.243 257.098 76.1891 148.972 292.386C144.279 301.77 141.412 312.031 139.821 322.401L122.795 433.36C120.943 445.434 120.944 457.721 122.801 469.794L136.744 560.487C140.314 583.71 150.582 605.398 166.166 622.982C204.905 666.695 253.008 720.082 277.455 721.667C314.764 724.084 443.12 755.959 443.12 755.959C443.12 755.959 564.834 781.557 704.901 707.66C763.826 664.191 872.992 529.879 869.117 491.281C861.544 415.843 775.72 480.159 725.79 504.821C675.488 536.737 460.284 632.757 406 534.038C287.5 318.538 704.901 504.821 574.884 161.485Z" fill="url(#paint0_linear_1803_190)"/>
      <path d="M574.884 161.485C553.586 105.243 257.098 76.1891 148.972 292.386C144.279 301.77 141.412 312.031 139.821 322.401L122.795 433.36C120.943 445.434 120.944 457.721 122.801 469.794L136.744 560.487C140.314 583.71 150.582 605.398 166.166 622.982C204.905 666.695 253.008 720.082 277.455 721.667C314.764 724.084 443.12 755.959 443.12 755.959C443.12 755.959 564.834 781.557 704.901 707.66C763.826 664.191 872.992 529.879 869.117 491.281C861.544 415.843 775.72 480.159 725.79 504.821C675.488 536.737 460.284 632.757 406 534.038C287.5 318.538 704.901 504.821 574.884 161.485Z" fill="url(#paint1_linear_1803_190)"/>
      <path d="M574.884 161.485C553.586 105.243 257.098 76.1891 148.972 292.386C144.279 301.77 141.412 312.031 139.821 322.401L122.795 433.36C120.943 445.434 120.944 457.721 122.801 469.794L136.744 560.487C140.314 583.71 150.582 605.398 166.166 622.982C204.905 666.695 253.008 720.082 277.455 721.667C314.764 724.084 443.12 755.959 443.12 755.959C443.12 755.959 564.834 781.557 704.901 707.66C763.826 664.191 872.992 529.879 869.117 491.281C861.544 415.843 775.72 480.159 725.79 504.821C675.488 536.737 460.284 632.757 406 534.038C287.5 318.538 704.901 504.821 574.884 161.485Z" stroke="black"/>
    </g>
    <defs>
      <filter id="filter0_f_1803_190" x="0.907104" y="0.5" width="988.811" height="880.247" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="60" result="effect1_foregroundBlur_1803_190"/>
      </filter>
      <linearGradient id="paint0_linear_1803_190" x1="510.256" y1="120.345" x2="510.256" y2="833.238" gradientUnits="userSpaceOnUse">
        <stop stop-color="#724AFF"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1803_190" x1="822.267" y1="641.974" x2="351.835" y2="387.921" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1B1A1C"/>
        <stop offset="1" stop-color="#1B1A1C" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "BackgroundImg"
}
</script>

<style scoped>

</style>