<template>
  <div class="main_page">
    <div class="main_page_block">
      <div class="main_page_block_bg">
        <BackgroundImg  v-if="!isMobDev" class="background"/>
        <BackgroundImgMob  v-if="isMobDev" class="background"/>
      </div>
      <logo-img class="logo"/>
      <div class="title">
        Cryptocurrency <br> News
      </div>
      <a href="https://t.me/+Woe6QgBy--k0Njcy" >
        <div class="btn">
          Open in Telegram
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import LogoImg from "@/assets/img/LogoImg";
import BackgroundImg from "@/assets/img/BackgroundImg";
import BackgroundImgMob from "@/assets/img/BackgroundImgMob";

export default {
  name: "MainPage",
  components: {BackgroundImgMob, BackgroundImg, LogoImg},
  data() {
    return {
      isMobDev: false,
    }
  },
  created() {
    window.addEventListener("resize", this.mobileHandler);
  },
  mounted() {
    this.mobileHandler();
  },
  methods: {
    mobileHandler() {
      this.isMobDev = window.screen.width < 1079;
      console.log(this.isMobDev)
    },
  }
}
</script>

<style lang="scss">
@import "../assets/styles/_main-page.scss";
</style>
